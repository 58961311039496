<template>
  <section>
    <div v-if="items && items.length > 0">
      <v-badge class="d-block my-4" dot bordered offset-x="6" offset-y="6" v-for="(item, index) in items" :key="index" :content="!item.isRead" :value="!item.isRead">
        <v-card class="border rounded-lg px-2 py-4"  hover ripple elevation="0">
          <span class="d-block f12 grey--text">{{ item.createDate | moment("dddd , jD jMMMM jYYYY") }}</span>
          <span class="d-block f15 font-weight-bold">{{item.title}}</span>
          <span class="d-block f14">{{item.message}}</span>
        </v-card>
      </v-badge>
    </div>
    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner">
        <v-icon>mdi-selection-ellipse spin</v-icon>
      </div>
      <div slot="no-more">
        <v-icon>mdi-access-point-off</v-icon>
      </div>
      <div slot="no-results">
        <v-icon>mdi-access-point-off</v-icon>
      </div>
    </infinite-loading>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {

      page: 1,
      size: 12,

      items: [],
    }
  },
  methods: {
    getNotifications(page, size) {
      apiService.getNotifications(page, size, "createDate,desc")
        .then((response) => {
          this.items = response.data.content;
        })
    },
    NotificationsMarkAsReadAll() {
      apiService.NotificationsMarkAsReadAll();
    },
    infiniteHandler($state) {
      apiService.getNotifications(this.page, this.size, "createDate,desc")
        .then((response) => {
          if (response.data.content.length) {
            response.data.content.forEach(element => {
              this.items.push(element);
            });

            this.page += 1;
            $state.loaded();
          }
          else {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.NotificationsMarkAsReadAll();
    this.getNotifications(0, this.size);
  },
}
</script>
